//© 2020, Zio Health Ltd. All rights reserved
import React, { Component } from "react";
import Loader from "react-loader-spinner";
import { CSVLink } from "react-csv";
import CSVReader from "react-csv-reader";
import * as math from "mathjs";

import Refresh from "../../img/refresh.jpg";
import Select from "react-select";
import { animateScroll } from "react-scroll";

//This Block to ensure there aren't multiple tabs connected to the same device
let connected_device_name = "";
window.addEventListener("unload", () => {
  removeLSConnectedDevice();
});
const removeLSConnectedDevice = () => {
  let connectedDevices = JSON.parse(
    window.localStorage.getItem("zio_connected_devices")
  );
  connectedDevices = connectedDevices.filter(
    (element) => element !== connected_device_name
  );
  window.localStorage.setItem(
    "zio_connected_devices",
    JSON.stringify(connectedDevices)
  );
};
const checkDuplicateConnections = () => {
  let connectedDevices = JSON.parse(
    window.localStorage.getItem("zio_connected_devices")
  );
  if (
    connectedDevices &&
    connectedDevices.indexOf(connected_device_name) !== -1
  )
    alert(
      "Multiple Connections to Device detected! Be sure to have only one Google Chrome tab connected to the ZiO Device before running a test otherwise data will be lost"
    );
};
const localStoreConnectedDevice = () => {
  let connectedDevices = JSON.parse(
    window.localStorage.getItem("zio_connected_devices")
  );
  checkDuplicateConnections();
  if (connectedDevices) connectedDevices.push(connected_device_name);
  else connectedDevices = [connected_device_name];
  window.localStorage.setItem(
    "zio_connected_devices",
    JSON.stringify(connectedDevices)
  );
};
const peakCurrentKeys = [
  "Peak_Current pA (Sample)",
  "Peak_Current pA (Reagent A)",
  "Peak_Current pA (Reagent B)",
  "Peak_Current pA (Reagent S)",
];
let outputHeadersAsStrings = [
  "Curve",
  "Device",
  "Length",
  "Electrode",
  "Frequency Hz",
  "Start_Voltage mV",
  "Range",
  "Peak_Current pA (Sample)",
  "Peak_Current pA (Reagent A)",
  "Peak_Current pA (Reagent B)",
  "Peak_Current pA (Reagent S)",
  "Error_Code",
  "SWVs",
  "Temperature",
];
let outputHeadersAsObjects = [];
let meta_data = [];
let concentrationParameters = [{}, {}, {}]; //Three sets of parameters for each temperature range
let temperatureRanges = []; // [[min, max], [min,max], [min,max]]
let baseCurrentMax = Infinity;
let reagentA_metadata = [];
let sample_metadata = [];
let reagentB_metadata = [];
let reagentS_metadata = [];
let ranReagentB = false;
let ranReagentS = false;
let sample_ratios = {};
let reagentA_ratios = {};
let reagentB_ratios = {};
let reagentS_ratios = {};
let max_render = 20; //Max number of cures to render
let curve_count = 0;
let new_message = "";
let current_curve_data = {};
let automation_configs = [];
let test_count = 0; // Used to determine whether current curve is NR or not
let current_data_length = 0;
let current_start_voltage = null;
let current_sweep_direction = null;
let test_state = "waiting";
let capturedMetaData = 0;
let metaDataLabels = [
  "Length",
  "Electrode",
  "Frequency Hz",
  "Start_Voltage mV",
  "Range",
  "Peak_Current pA (Reagent A)",
  "Error_Code",
  "SWVs",
  "Temperature",
  "Sensor Ambient Temperature",
  "Ambient Temperature",
  "Ambient Humidity",
  "Chip Temperature",
  "Noise Level",
  "Sweep Direction",
];
let previousPeak = null; //For concentration equation
let previousError = false; //For concentration equation
let previousFreq = null; //For ignoring dummy data
let dontPushMetaData = false;
let notPushedMetaData = [];
let concentrationEquationOptions = [
  { value: 1, label: "Linear" },
  { value: 2, label: "Hyperbolic" },
  { value: 3, label: "Exponential" },
];
let sweepDirectionOptions = [
  { value: 0, label: "Low to High" },
  { value: 1, label: "High to Low" },
];
let data_count = 0;
let last_written_data = {};
let db = null;
let apiURL = "https://9dc44g79m0.execute-api.us-east-1.amazonaws.com/dev";
if (process.env.REACT_APP_AJ_TEST_PC === "enabled")
  apiURL = "http://localhost:8000/dev";

if (!("indexedDB" in window)) {
  console.log("This browser doesn't support IndexedDB");
}

class SettingsMenu extends Component {
  constructor(props) {
    console.log("Build Version E-ML-1.2");
    super(props);
    this.state = {
      outputData: [],
      save_csv_data: [],
      serumOrBlood: "serum",
      totalVancomycinLevel: 0,
      firstRunOfAutomation: true,
      debug: false,
      show_error: false,
      error_message: "",
      preparing_save: false,
      status_message: "",
      apparent_sample_output: "",
      user_disconnected: false,
      battery_level: 100,
      loading_file: false,
      automated_test: false,
      characteristics_menu_open: false,
      loading: false,
      device_name: null,
      device_paired: null,
      current_service: null,
      menu_open: false,
      running_test: false,
      cart_detect: 0,
      frequency_NR: 180,
      frequency: 400,
      start_voltage: -300,
      range: 7,
      range_NR: 7,
      we_selected: 1,
      SWV: 3,
      selectedEquation: concentrationEquationOptions[0],
      A: 0,
      B: 0,
      N: 0,
      concentration_avg_filter: 0,
      selectedSweepDirection: sweepDirectionOptions[0],
      selectCalibrated: "",
      predictionResults: [],
      stackingPredictions: [],
    };
  }

  componentDidMount = () => {
    //setInterval( this.updateBattery, 20000);
  };

  flushMemory = () => {
    meta_data = [];
    curve_count = 0;
    new_message = "";
    current_curve_data = {};
    automation_configs = [];
    test_count = 0;
    current_start_voltage = null;
    test_state = "waiting";
    capturedMetaData = 0;
    let objectStore = db
      .transaction(["dataBuffer"], "readwrite")
      .objectStore("dataBuffer");
    let req = objectStore.clear();
    let objectStore2 = db
      .transaction(["renderData"], "readwrite")
      .objectStore("renderData");
    req = objectStore.clear();
    this.props.updateGraphMethod([], []);
    this.setState({ status_message: "", outputData: [] });
  };

  exportedData = () => {
    this.setState({ show_error: false });
  };

  saveMetaData = () => {
    if (this.state.automated_test)
      current_curve_data["automated_row_number"] = automation_configs[0].Order;
    new_message = new_message + "\n \n";
    Object.keys(current_curve_data).map((el) => {
      new_message = new_message + "\n " + el + ": " + current_curve_data[el];
    });
    current_sweep_direction = parseInt(current_curve_data["Sweep Direction"]);
    // If sweep direction is high to low, start voltage is - 500
    current_start_voltage =
      current_sweep_direction === 1
        ? parseInt(current_curve_data["Start_Voltage mV"]) - 500
        : parseInt(current_curve_data["Start_Voltage mV"]);
    this.setState({ status_message: new_message });
    setTimeout(() => {
      animateScroll.scrollToBottom({
        containerId: "status-message",
      });
    }, 500);
  };

  shouldDeleteDummyData = () => {
    //When Freq === FreqNR this indicates dummy data and metadata should be deleted
    console.log(previousFreq, "previousFreq");
    console.log(JSON.stringify(current_curve_data), "current_curve_data");
    if (previousFreq === current_curve_data["Frequency Hz"]) {
      dontPushMetaData = true;
      return true;
    }
    return false;
  };

  //This method is triggered every time a bluetooth notification is recieved on the device
  handleCharacteristicValueChanged = async (event) => {
    let peakCurrentLabel = "Peak_Current pA (Sample)";
    if (this.state.runningReagentA) {
      peakCurrentLabel = "Peak_Current pA (Reagent A)";
    } else if (sample_metadata.length > 0 && !ranReagentS) {
      peakCurrentLabel = "Peak_Current pA (Reagent B)";
    } else if (ranReagentS) {
      peakCurrentLabel = "Peak_Current pA (Reagent S)";
    }
    let dv = event.target.value;
    let incomingPackets = [
      dv.getInt32(0, true),
      dv.getInt32(4, true),
      dv.getInt32(8, true),
      dv.getInt32(12, true),
      dv.getInt32(16, true),
    ];
    if (this.state.debug) console.log("Raw data packet", incomingPackets);
    while (incomingPackets.length > 0) {
      let currentDataVal = incomingPackets[0];
      if (currentDataVal === -1431655766) {
        test_state = "metadata";
        this.setState({ loading: true });
      } else if (currentDataVal === -572662307) {
        test_state = "testdata";
        capturedMetaData = 0;
        current_data_length = 0;
        this.saveMetaData();
      } else if (currentDataVal === 1431655765) {
        test_state = "waiting";
        if (!dontPushMetaData) {
          meta_data.push(current_curve_data);
        } else {
          notPushedMetaData.push(meta_data.pop());
          notPushedMetaData.push(current_curve_data);
          dontPushMetaData = false;
          previousFreq = null;
          console.log("setting prevfreq = null after notpushedmetadata");
        }
        if (curve_count % 2 !== 0) {
          if (current_sweep_direction === 1)
            this.updateGraph(
              current_curve_data["Start_Voltage mV"] - 1,
              curve_count
            );
          else
            this.updateGraph(
              current_data_length -
                Math.abs(current_curve_data["Start_Voltage mV"]) -
                1,
              curve_count
            );
          // this.show_filtered_concentration();
        }
        curve_count++;
        current_curve_data = {};
        data_count = 0;
        test_count--;
      } else if (test_state === "metadata") {
        if (capturedMetaData >= metaDataLabels.length) {
          console.log(
            "Extra meta data values found but there are not enough labels defined"
          );
        } else {
          if (capturedMetaData === 0) {
            current_curve_data["Curve"] = "y" + curve_count + " ADC";
            current_curve_data["Device"] = this.state.device_name;
          }
          // This is where metadata is stored in current_curve_data
          const currentLabel = metaDataLabels[capturedMetaData];
          if (
            currentLabel === "Temperature" ||
            currentLabel === "Sensor Ambient Temperature" ||
            currentLabel === "Temperature" ||
            currentLabel === "Ambient Temperature" ||
            currentLabel === "Ambient Humidity" ||
            currentLabel === "Chip Temperature"
          )
            current_curve_data[currentLabel] = currentDataVal / 100;
          // temp values need to be divided by 100
          else current_curve_data[currentLabel] = currentDataVal;
          //Store freq peak so concentration can be calculated for freqNR
          if (
            capturedMetaData + 1 == metaDataLabels.length &&
            previousPeak != null
          ) {
            //Only store data if not dummy
            if (!this.shouldDeleteDummyData()) {
              console.log("setting prevfreq = null");
              previousPeak = null;
              previousFreq = null;
              previousError = false;
            }
          } else if (
            !previousPeak &&
            capturedMetaData + 1 == metaDataLabels.length
          ) {
            if (!this.shouldDeleteDummyData()) {
              console.log("setting prevfreq");
              if (current_curve_data["Error_Code"] != 0) previousError = true;
              else previousPeak = current_curve_data[peakCurrentLabel];
              previousFreq = current_curve_data["Frequency Hz"];
            }
          }
          capturedMetaData++;
        }
      } else if (test_state === "testdata") {
        current_data_length++;
        if (data_count < 10 && currentDataVal > baseCurrentMax) {
          this.setState({
            show_error: true,
            error_message:
              "Base current" +
              currentDataVal +
              " is too high. (>" +
              baseCurrentMax +
              ") \n Change the cartridge/chip.",
          });
        }
        this.storeDataBuffer(
          {
            x: current_start_voltage + data_count,
            ["y" + curve_count + " ADC"]: currentDataVal,
          },
          curve_count
        );
        data_count++;
      }
      incomingPackets.shift();
    }
  };

  /*  I think the curveCount is passed into this
      (as aposed to global variable: curve_count)
      function because we are limiting it to only
      the curves we have the data for.
  */
  updateGraph = (expected_length, curveCount) => {
    let curves = [];
    // This if ensure all data points are written to indexedDB before rendering to graph
    if (last_written_data[curveCount] >= expected_length) {
      if (curveCount + 1 <= max_render) {
        for (let i = 0; i <= curveCount; i++) {
          curves.push(i);
        }
        let objectStore = db
          .transaction(["dataBuffer"], "readwrite")
          .objectStore("dataBuffer");
        let req2 = objectStore.getAll();
        req2.onerror = (e) => {
          console.log(e);
        };
        req2.onsuccess = (e) => {
          if (this.state.debug) console.log(e.target.result);
          this.props.updateGraphMethod(e.target.result, curves);
          this.setState({ loading: false });
          if (this.state.automated_test) {
            console.log(
              "Automated row order number: " +
                automation_configs[0].Order +
                " " +
                (test_count % 2 == 0 ? "" : "(NR)")
            );
            if (test_count == 0) {
              // If all tests (for method row) were run
              // and were still doing automated testing go onto the next row;
              automation_configs.shift();
              this.run_automated_tests(automation_configs);
            }
          }
        };
      } else {
        let firstCurve = curveCount - max_render + 1;
        for (let i = firstCurve; i <= curveCount; i++) {
          curves.push(i);
        }
        let objectStore = db
          .transaction(["renderData"], "readwrite")
          .objectStore("renderData");
        let req2 = objectStore.getAll();
        req2.onerror = (e) => {
          console.log(e);
        };
        req2.onsuccess = (e) => {
          if (this.state.debug) console.log(e.target.result);
          this.props.updateGraphMethod(e.target.result, curves);
          this.setState({ loading: false });
          if (this.state.automated_test) {
            console.log(
              "Automated row order number: " +
                automation_configs[0].Order +
                " " +
                (test_count % 2 == 0 ? "" : "(NR)")
            );
            if (test_count == 0) {
              //If both tests were run and were still doing automated testing go onto the next row;
              automation_configs.shift();
              this.run_automated_tests(automation_configs);
            }
          }
        };
      }
    } else {
      setTimeout((e) => {
        this.updateGraph(expected_length, curveCount);
      }, 1000);
    }
  };

  storeDataBuffer = (obj, curveCount) => {
    let objectStore = db
      .transaction(["dataBuffer"], "readwrite")
      .objectStore("dataBuffer");
    let getReq = objectStore.get(data_count + current_start_voltage);
    getReq.onerror = async (e) => {
      console.log("error ");
      return;
    };

    getReq.onsuccess = async (e) => {
      let dataToAdd = obj;
      if (e.target.result) {
        //Combine new and old
        dataToAdd = { ...obj, ...e.target.result };
      }
      let dataBufferTransaction = db.transaction(["dataBuffer"], "readwrite");
      let objectStore = dataBufferTransaction.objectStore("dataBuffer");
      let requestUpdate = objectStore.put(dataToAdd);
      requestUpdate.addEventListener("error", (event) => {
        console.log("Request error:", requestUpdate.error);
      });
      requestUpdate.onerror = function(event) {
        console.log("error storing data in indexedDB");
        return;
      };
      requestUpdate.onsuccess = function(event) {
        if (curveCount + 1 === max_render && obj.x == current_start_voltage) {
          //console.log("maxRender reached", curveCount, max_render);
          let req2 = objectStore.getAll();
          req2.onerror = (e) => {
            console.log(e);
          };
          req2.onsuccess = (e) => {
            let renderDataStore = db
              .transaction(["renderData"], "readwrite")
              .objectStore("renderData");
            e.target.result.forEach((value) => {
              renderDataStore.put(value);
            });
          };
        } else if (curveCount + 1 > max_render) {
          //console.log("maxRender exceeded", curveCount, max_render);
          let renderDataStore = db
            .transaction(["renderData"], "readwrite")
            .objectStore("renderData");
          //First curve to delete
          let firstCurve = curveCount - max_render - 1;
          if (obj.x == current_start_voltage && curveCount % 2 == 0) {
            //curve_count % 2 is to make sure this is only done once for each pair
            let req2 = renderDataStore.getAll();
            requestUpdate.addEventListener("error", (event) => {
              console.log("Request error:", requestUpdate.error);
            });
            req2.onerror = (e) => {
              console.log(e);
            };
            req2.onsuccess = (e) => {
              e.target.result.forEach((value) => {
                delete value["y" + firstCurve + " ADC"];
                delete value["y" + (firstCurve + 1) + " ADC"];
                if (Object.keys(value).length <= 1)
                  renderDataStore.delete(value.x);
                else renderDataStore.put(value);
              });
            };
          }
          delete dataToAdd["y" + firstCurve + " ADC"];
          delete dataToAdd["y" + (firstCurve + 1) + " ADC"];
          let requestUpdate2 = renderDataStore.put(dataToAdd);
          requestUpdate.onerror = function(event) {
            console.log("error storing data in indexedDB");
            return;
          };
        }
        //return;
      };
      dataBufferTransaction.oncomplete = () => {
        if (
          last_written_data[curveCount] &&
          obj.x != last_written_data[curveCount] + 1
        )
          console.log(
            "MISSED DATA AT CURVE",
            curveCount,
            obj.x,
            last_written_data[curveCount]
          );
        last_written_data[curveCount] = obj.x;
      };
      dataBufferTransaction.onabort = () => {
        console.log(
          "TRANSACTION ABORTED CURVE",
          curveCount,
          dataBufferTransaction.error
        );
      };
    };
  };

  pairDevice = async () => {
    let pairedDevice = null;
    try {
      console.log("Trying to pair...");
      //Below is a request that uses a filter, so no irrelevant bluetooth devices are shown
      //navigator.bluetooth.requestDevice({filters: [{name: 'Potentiostat'}], optionalServices: ['16d30bc1-f148-49bd-b127-8042df63ded0']})
      let pairedDevice = await navigator.bluetooth.requestDevice({
        filters: [{ namePrefix: "ZIO" }],
        optionalServices: [
          "16d30bc1-f148-49bd-b127-8042df63ded0",
          "0000180a-0000-1000-8000-00805f9b34fb",
        ],
      });
      let device = pairedDevice;
      connected_device_name = device.name;
      localStoreConnectedDevice();
      this.setState({ loading: true, user_disconnected: false });
      pairedDevice.addEventListener(
        "gattserverdisconnected",
        this.onDisconnected
      );
      // if (this.state.debug)
      // console.log("pairedDevice", JSON.stringify(pairedDevice), pairedDevice, device);
      // Attempts to connect to remote GATT Server.
      let server = await device.gatt.connect();
      console.log("server", JSON.stringify(server));
      let service = await server.getPrimaryService(
        "16d30bc1-f148-49bd-b127-8042df63ded0"
      );
      let infoService = await server.getPrimaryService(
        "0000180a-0000-1000-8000-00805f9b34fb"
      );
      let enc = new TextDecoder("utf-8");
      let firmwareCharacteristic = await infoService.getCharacteristic(
        "00002a26-0000-1000-8000-00805f9b34fb"
      );
      let firmwareValue = await firmwareCharacteristic.readValue();
      let firmwareString = enc.decode(firmwareValue);
      console.log("service", JSON.stringify(service));
      let request = window.indexedDB.open(pairedDevice.id, 2);
      request.onerror = function(event) {
        console.log("Error loading IndexedDB, code:", event.target.errorCode);
      };
      request.onsuccess = function(event) {
        db = event.target.result;
        let objectStore = db
          .transaction(["dataBuffer"], "readwrite")
          .objectStore("dataBuffer");
        objectStore.clear();
        let req2 = objectStore.getAll();
        req2.onerror = (e) => {
          console.log(e);
        };
        req2.onsuccess = (e) => {
          console.log("IndexedDB connected.");
        };

        let objectStore2 = db
          .transaction(["renderData"], "readwrite")
          .objectStore("renderData");
        objectStore2.clear();
      };
      request.onupgradeneeded = function(event) {
        db = event.target.result;
        var objectStore = db.createObjectStore("dataBuffer", { keyPath: "x" });
        var objectStore = db.createObjectStore("renderData", { keyPath: "x" });
      };
      this.setState({
        loading: false,
        device_name: pairedDevice.name,
        device_paired: pairedDevice,
        current_service: service,
        firmware: firmwareString,
        show_error: false,
      });
      this.updateBattery();
    } catch (error) {
      console.error("Pairing failed", error);
      this.setState({
        loading: false,
        user_disconnected: true,
        device_name: null,
        device_paired: null,
        current_service: null,
      });
      if (pairedDevice) pairedDevice.gatt.disconnect();
    }
  };

  disconnectDevice = () => {
    if (this.state.device_paired) {
      removeLSConnectedDevice();
      this.setState(
        {
          error_message: "Device Disconnected",
          show_error: true,
          user_disconnected: true,
          current_service: null,
          // menu_open: false,
        },
        () => {
          this.state.device_paired.gatt.disconnect();
        }
      );
    }
  };

  onDisconnected = (e) => {
    console.log("> Bluetooth Device disconnected");
    try {
      this.state.device_paired.gatt.disconnect();
    } catch (e) {
      console.error(e);
    }
    if (!this.state.user_disconnected) {
      removeLSConnectedDevice();
      this.setState({ show_error: true, error_message: "Device Disconnected" });
      this.reconnectDevice();
    }
  };

  logTime = (text) => {
    console.log("[" + new Date().toJSON().substr(11, 8) + "] " + text);
  };

  re_run_automated_tests = () => {
    console.log("attempt rerun automated test");
    if (this.state.automated_test) {
      // Calculate how many curves were expected for current method row
      // Given the amount of electrodes selected (WE Select)
      const row_curve_max =
        ((automation_configs[0]["WE Select"] + "").split("1").length - 1) * 2;
      const curves_completed = row_curve_max - test_count;
      // Reset the curve count so the whole row can be recalculated
      // as disconnect might have incomplete curves
      for (let i = 0; i < curves_completed; i++) {
        curve_count--;
      }
      previousPeak = null;
      previousFreq = null;
      console.log("Re running tests with curve count: ", curve_count);
      console.log(previousFreq, "previousFreq");
      console.log(previousPeak, "previousPeak");
      this.run_automated_tests(automation_configs);
      return;
    }
  };

  runReagentB = (fromLabel = "Sample") => {
    ranReagentB = true;
    this.setState({
      firstRunOfAutomation: true,
      show_error: false,
      error_message: "",
    });
    metaDataLabels[metaDataLabels.indexOf(`Peak_Current pA (${fromLabel})`)] =
      "Peak_Current pA (Reagent B)";
    this.start_run_test();
  };

  runReagentS = () => {
    ranReagentS = true;
    this.setState({
      firstRunOfAutomation: true,
      show_error: false,
      error_message: "",
    });
    metaDataLabels[metaDataLabels.indexOf("Peak_Current pA (Sample)")] =
      "Peak_Current pA (Reagent S)";
    this.start_run_test();
  };

  runSampleTest = (fromLabel = "Reagent A") => {
    this.setState({
      firstRunOfAutomation: true,
      runningSampleTest: true,
      show_error: false,
      error_message: "",
    });
    metaDataLabels[metaDataLabels.indexOf(`Peak_Current pA (${fromLabel})`)] =
      "Peak_Current pA (Sample)";
    this.start_run_test();
  };

  reconnectDevice = () => {
    if (!this.state.device_paired) return;
    this.exponentialBackoff(
      15 /* max retries */,
      2 /* seconds delay */,
      () => {
        //toTry()
        this.logTime("Reconnecting to Bluetooth Device... ");
        return this.state.device_paired.gatt.connect();
      },
      (server) => {
        //success()
        if (this.state.debug) console.log("server", server);
        server
          .getPrimaryService("16d30bc1-f148-49bd-b127-8042df63ded0")
          .then((service) => {
            this.setState({ current_service: service, show_error: false });
            console.log("> Bluetooth Device connected. Reconnected");
            this.re_run_automated_tests();
          })
          .catch((error) => {
            console.log("Reconnect to Service", error);
            this.reconnectDevice(); //Try again
          });
      },
      () => {
        //fail()
        this.logTime("Failed to reconnect.");
      }
    );
  };

  // This function keeps calling "toTry" until promise resolves or has
  // retried "max" number of times. First retry has a delay of "delay" seconds.
  // "success" is called upon success.
  exponentialBackoff = (max, delay, toTry, success, fail) => {
    toTry()
      .then((result) => success(result))
      .catch((e) => {
        if (max === 0) {
          return fail();
        }
        console.error(e);
        this.logTime("Retrying in " + delay + "s... (" + max + " tries left)");
        setTimeout(() => {
          this.exponentialBackoff(--max, delay * 2, toTry, success, fail);
        }, delay * 1000);
      });
  };

  updateCharacteristics = async (run_test_after) => {
    this.setState({ menu_open: false, loading: true });
    // Dataview needs to be made to make a big endian ArrayBuffer

    let buffer = null;
    let dv = null;
    let SWV_enabled = true;

    try {
      buffer = new ArrayBuffer(1);
      dv = new DataView(buffer);
      dv.setUint8(0, parseInt(this.state.SWV), false);
      let SWV_characteristic = await this.state.current_service.getCharacteristic(
        "16d30bce-f148-49bd-b127-8042df63ded0"
      );

      if (this.state.SWV < 0) {
        alert("SWV characteristic must be greater than 0");
        return;
      }
      SWV_characteristic.writeValue(dv.buffer);
    } catch (e) {
      SWV_enabled = false;
      console.log(e, ": error updating SWV");
    }

    // Write to BLE_UUID_ZIO_FREQ1_CHAR
    buffer = new ArrayBuffer(2);
    dv = new DataView(buffer);
    dv.setUint16(0, parseInt(this.state.frequency), false);
    let freq1_characteristic = await this.state.current_service.getCharacteristic(
      "16d30bc2-f148-49bd-b127-8042df63ded0"
    );
    freq1_characteristic.writeValue(dv.buffer);

    // Write to BLE_UUID_ZIO_FREQNR_CHAR
    buffer = new ArrayBuffer(2);
    dv = new DataView(buffer);
    dv.setUint16(0, parseInt(this.state.frequency_NR), false);
    let freqNR_characteristic = await this.state.current_service.getCharacteristic(
      "16d30bc3-f148-49bd-b127-8042df63ded0"
    );
    freqNR_characteristic.writeValue(dv.buffer);

    // Write to BLE_UUID_ZIO_START_VOLT_CHAR
    buffer = new ArrayBuffer(2);
    dv = new DataView(buffer);
    dv.setInt16(0, parseInt(this.state.start_voltage), false);
    let startV_characteristic = await this.state.current_service.getCharacteristic(
      "16d30bc4-f148-49bd-b127-8042df63ded0"
    );
    startV_characteristic.writeValue(dv.buffer);

    // Write to BLE_UUID_ZIO_RANGE1_CHAR
    buffer = new ArrayBuffer(1);
    dv = new DataView(buffer);
    dv.setUint8(0, parseInt(this.state.range), false);
    let range1_characteristic = await this.state.current_service.getCharacteristic(
      "16d30bc6-f148-49bd-b127-8042df63ded0"
    );
    range1_characteristic.writeValue(dv.buffer);

    // Write to BLE_UUID_ZIO_RANGENR_CHAR
    buffer = new ArrayBuffer(1);
    dv = new DataView(buffer);
    dv.setUint8(0, parseInt(this.state.range_NR), false);
    let rangeNR_characteristic = await this.state.current_service.getCharacteristic(
      "16d30bc7-f148-49bd-b127-8042df63ded0"
    );
    rangeNR_characteristic.writeValue(dv.buffer);

    // Write to BLE_UUID_WE_SELECT
    buffer = new ArrayBuffer(4);
    dv = new DataView(buffer);
    dv.setUint32(0, parseInt(this.state.we_selected), false);
    let WE_characteristic = await this.state.current_service.getCharacteristic(
      "16d30bcb-f148-49bd-b127-8042df63ded0"
    );
    WE_characteristic.writeValue(dv.buffer);

    // Write to sweep direction characteristic
    buffer = new ArrayBuffer(1);
    dv = new DataView(buffer);
    if (this.state.selectedSweepDirection)
      dv.setUint8(0, parseInt(this.state.selectedSweepDirection.value), false);
    let sweepDirection_characteristic = await this.state.current_service.getCharacteristic(
      "16d30bd2-f148-49bd-b127-8042df63ded0"
    );
    sweepDirection_characteristic.writeValue(dv.buffer);

    let message =
      "\n \n Characteristics updated: \n Frequency1: " +
      this.state.frequency +
      "\n Frequency2: " +
      this.state.frequency_NR +
      "\n Start Voltage: " +
      this.state.start_voltage +
      "\n Range1: " +
      this.state.range +
      "\n RangeNR: " +
      this.state.range_NR +
      "\n WE Selected: " +
      this.state.we_selected +
      "\n Sweep Direction: " +
      this.state.selectedSweepDirection.label;

    if (SWV_enabled) message = message + "\n SWV: " + this.state.SWV;
    this.setState({
      status_message: this.state.status_message + message,
      loading: false,
    });
    setTimeout(() => {
      animateScroll.scrollToBottom({
        containerId: "status-message",
      });
    }, 500);

    if (run_test_after) this.runTest();
  };

  runTest = async () => {
    console.log("Attempting to run tests...");

    test_count = ((this.state.we_selected + "").split("1").length - 1) * 2;

    const ensureServiceReady = async () => {
      try {
        let characteristic = await this.state.current_service.getCharacteristic(
          "16d30bc5-f148-49bd-b127-8042df63ded0"
        );
        await characteristic.readValue();
        return true;
      } catch (error) {
        console.log("Service not ready, retrying...", error);
        return false;
      }
    };
    let serviceReady = false;
    while (!serviceReady) {
      serviceReady = await ensureServiceReady();
      if (!serviceReady) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
    }

    try {
      // Cartridge detect
      let characteristic = await this.state.current_service.getCharacteristic(
        "16d30bc5-f148-49bd-b127-8042df63ded0"
      );
      let value = await characteristic.readValue();

      // if there is no cartridge detected value.getInt8 should == 0
      if (value.getInt8() == 0) {
        console.log("No cartridge detected");
        alert("No cartridge detected");
        return;
      } else {
        // Listen to Data characteristic
        let dataCharacteristic = await this.state.current_service.getCharacteristic(
          "16d30bc9-f148-49bd-b127-8042df63ded0"
        );
        await dataCharacteristic.startNotifications();
        dataCharacteristic.addEventListener(
          "characteristicvaluechanged",
          this.handleCharacteristicValueChanged
        );

        // Write 1 to CMD characteristic to start test
        let CMDCharacteristic = await this.state.current_service.getCharacteristic(
          "16d30bc8-f148-49bd-b127-8042df63ded0"
        );
        console.log("running Tests");
        await CMDCharacteristic.writeValue(Uint8Array.of(1));
        this.setState({ loading: true });
      }
    } catch (error) {
      console.log("Error during test run:", error);
    }
  };

  toggleMenuOpen = () => {
    this.setState({
      show_error: false,
      menu_open: !this.state.menu_open,
      characteristics_menu_open: false,
      loading_file: false,
    });
  };

  open_characteristics_menu = () => {
    this.setState({ characteristics_menu_open: true });
  };

  handleInputParameterChange = (e) => {
    if (this.state.debug)
      console.log("target, name:", e.target.value, e.target.name);

    if (e.target.value == "on" || e.target.value == "off") {
      this.setState({ [e.target.name]: !this.state[e.target.name] });
      return;
    }

    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  stop_automated_test = () => {
    this.setState({ automated_test: false, menu_open: false });
    console.log("Stopping automated test (not running next row)..");
  };

  test_write_result = async (testResult, errorCode = 0) => {
    if (process.env.REACT_APP_AJ_TEST_PC === "enabled") return;
    // Ensure the inputs are whole numbers
    if (!Number.isInteger(errorCode) || !Number.isInteger(testResult)) {
      throw new Error("Both inputs should be whole integers.");
    }

    // Check that the numbers are within the correct range
    if (errorCode < 0 || errorCode > 15) {
      throw new Error("errorCode needs to be in the range of 0-15 (4 bits)");
    }

    // Check testResult against 28 bits maximum value
    const maxtestResult = (1 << 28) - 1; // Calculate maximum value using bit shift
    if (testResult < 0 || testResult > maxtestResult) {
      throw new Error(
        `testResult needs to be in the range of 0-${maxtestResult} (28 bits)`
      );
    }

    // Create a new ArrayBuffer and a view to manipulate it
    const buffer = new ArrayBuffer(4); // 4 bytes for 32 bits
    const view = new DataView(buffer);

    // Combine the numbers into a 32-bit integer
    const combined = (errorCode << 28) | (testResult & 0xfffffff); // Ensure otherNumber occupies only 28 bits
    view.setUint32(0, combined, false); // false for big-endian byte order

    let testResult_characteristic = await this.state.current_service.getCharacteristic(
      "16d30bd4-f148-49bd-b127-8042df63ded0"
    );
    testResult_characteristic.writeValue(view.buffer);
  };

  open_loading_file = () => {
    this.setState({ loading_file: true });
  };

  removeOutliers = (obj) => {
    // Convert object values to array
    const values = Object.values(obj);

    // Calculate mean
    const mean =
      values.reduce((total, value) => total + value, 0) / values.length;

    // Calculate standard deviation
    const sd = Math.sqrt(
      values.reduce((total, value) => total + Math.pow(value - mean, 2), 0) /
        values.length
    );

    if (!sd || !mean) return obj;

    // Filter out values more than 2 standard deviations from the mean
    const result = Object.keys(obj)
      .filter((key) => Math.abs(obj[key] - mean) <= 2 * sd)
      .reduce((result, key) => {
        result[key] = obj[key];
        return result;
      }, {});

    console.log("removed outliers");
    console.log(JSON.stringify(obj), "obj");
    console.log(JSON.stringify(result), "result");
    return result;
  };

  getPeakCurrentKey = (row) => {
    return peakCurrentKeys.find((key) => key in row && row[key] !== undefined);
  };

  getPeakCurrentValue = (row) => {
    return row[this.getPeakCurrentKey(row)];
  };

  calculate_ratio_averages = (inputMetadata, label) => {
    //Build frequency and electrode dictionary for each curve
    let freq_electrode_arrays = {}; // {"freq_elec": [x1,x2 .. xn]}
    inputMetadata.forEach((row) => {
      //Ensure the right peak current is used for the row
      // essentially just used whichever is defined
      let rowPeakCurrent;
      if (process.env.REACT_APP_AJ_TEST_PC === "enabled") rowPeakCurrent = 1.5;
      else rowPeakCurrent = this.getPeakCurrentValue(row);
      if (row["Frequency Hz"] + "_" + row.Electrode in freq_electrode_arrays) {
        freq_electrode_arrays[row["Frequency Hz"] + "_" + row.Electrode].push(
          rowPeakCurrent || 0
        );
      } else {
        freq_electrode_arrays[row["Frequency Hz"] + "_" + row.Electrode] = [
          rowPeakCurrent || 0,
        ];
      }
    });
    console.log(
      JSON.stringify(freq_electrode_arrays),
      "freq_electrode_arrays - before"
    );

    //For each electrode/freq pair, remove 0 peaks if there are less than half of total peaks
    //If more than half, disregard whole electrode peaks
    let pairs = Object.keys(freq_electrode_arrays);
    for (const pair of pairs) {
      let zeroCount = 0;
      let totalPeaks = freq_electrode_arrays[pair].length;

      // Count the number of zeroes
      for (const peak of freq_electrode_arrays[pair]) {
        if (peak === 0) {
          zeroCount++;
        }
      }

      if (zeroCount <= totalPeaks / 2) {
        // Remove all 0 values from the array
        freq_electrode_arrays[pair] = freq_electrode_arrays[pair].filter(
          (peak) => peak !== 0
        );
      } else {
        // Remove the whole pair
        delete freq_electrode_arrays[pair];
      }
    }

    console.log(
      JSON.stringify(freq_electrode_arrays),
      "freq_electrode_arrays - after"
    );

    let tempRatio = {};
    let ratios = {};
    pairs = Object.keys(freq_electrode_arrays);
    pairs.forEach((currentPair) => {
      console.log(
        JSON.stringify(freq_electrode_arrays),
        currentPair,
        "freq_electrode_arrays, currentPair"
      );
      console.log(
        JSON.stringify(freq_electrode_arrays[currentPair]) || 0,
        "freq_electrode_arrays[currentPair] || 0"
      );
      let avgPeak = math.mean(freq_electrode_arrays[currentPair] || 0);
      let currentElectrode = label + "_Electrode_" + currentPair.split("_")[1];
      // calculate the ratio freqNR/freq for each electrode
      if (tempRatio[currentElectrode]) {
        // always bigger frequency as nominator and smaller freq and denominator
        if (
          parseInt(tempRatio[currentElectrode].freq) >
          parseInt(currentPair.split("_")[0])
        )
          ratios[currentElectrode] = tempRatio[currentElectrode].val / avgPeak;
        else
          ratios[currentElectrode] = avgPeak / tempRatio[currentElectrode].val;
      } else {
        tempRatio[currentElectrode] = {
          val: avgPeak,
          freq: currentPair.split("_")[0],
        };
      }
    });
    console.log(JSON.stringify(ratios), "ratios");
    // Filter out invalid entries
    const validRatios = Object.values(ratios).filter(
      (num) => num !== null && !isNaN(num) && num > 0 && num !== Infinity
    );
    let ratio_sum = validRatios.reduce((a, b) => a + b, 0);
    let ratio_avg = ratio_sum / validRatios.length;
    ratios[label + "_Total_Average"] = ratio_avg;
    console.log(
      ratio_avg,
      "average ratio across all electrodes for ",
      label,
      " method"
    );
    return ratios;
  };

  prepare_save_reagentA = (popup_message) => {
    reagentA_metadata = [...meta_data];
    meta_data = [];
    reagentA_ratios = this.calculate_ratio_averages(
      [...reagentA_metadata],
      "ReagentA"
    );
    reagentA_ratios = this.removeOutliers(reagentA_ratios);
    this.state.reagentAMethodData = null;
    this.setState({
      apparent_sample_output: reagentA_ratios["ReagentA_Total_Average"],
      firstRunOfAutomation: true,
      show_error: true,
      error_message: popup_message,
    });
  };

  prepare_save_sample = (
    popup = "Discard the patient sample, add reagent B to the cartridge",
    thenCompleteTest = false
  ) => {
    sample_metadata = [...meta_data];
    console.log(JSON.stringify(sample_metadata), "sample_metadata");
    meta_data = [];
    sample_ratios = this.calculate_ratio_averages(
      [...sample_metadata],
      "Sample"
    );
    sample_ratios = this.removeOutliers(sample_ratios);
    console.log(JSON.stringify(sample_ratios), "sample_ratios");
    if (thenCompleteTest) this.prepare_export();
    else
      this.setState({
        apparent_sample_output: sample_ratios["Sample_Total_Average"],
        show_error: true,
        automated_test: false,
        error_message: popup,
        firstRunOfAutomation: true,
      });
  };

  prepare_save_reagentB = () => {
    reagentB_metadata = [...meta_data];
    console.log(reagentB_metadata, "reagentB_metadata");
    reagentB_ratios = this.calculate_ratio_averages(
      [...reagentB_metadata],
      "ReagentB"
    );
    reagentB_ratios = this.removeOutliers(reagentB_ratios);
    meta_data = [];
    this.setState({
      apparent_sample_output: reagentB_ratios["ReagentB_Total_Average"],
      show_error: true,
      automated_test: false,
      error_message:
        "Discard Reagent B, add the patient sample to the cartridge",
      firstRunOfAutomation: true,
    });
  };

  prepare_save_reagentS = () => {
    reagentS_metadata = [...meta_data];
    console.log(reagentS_metadata, "reagentS_metadata");
    reagentS_ratios = this.calculate_ratio_averages(
      [...reagentS_metadata],
      "ReagentS"
    );
    reagentS_ratios = this.removeOutliers(reagentS_ratios);
    this.prepare_export();
  };

  prepare_emergency_save = () => {
    this.prepare_save_reagentA();
    this.prepare_save_sample();
    this.prepare_save_reagentB();
    this.prepare_save_reagentS();
    this.prepare_export();
  };
  calculateTemperatureRangeIndex = () => {
    const combined_meta_data = []
      .concat(reagentA_metadata)
      .concat(reagentB_metadata)
      .concat(sample_metadata)
      .concat(reagentS_metadata);
    let sum = 0;
    for (const obj of combined_meta_data) {
      sum += obj.Temperature;
    }
    const averageTemp = sum / combined_meta_data.length / 100;
    console.log(averageTemp, "(averageTemp)");
    if (
      temperatureRanges[2] &&
      temperatureRanges[2].length > 1 &&
      parseFloat(temperatureRanges[2][0]) < averageTemp &&
      parseFloat(temperatureRanges[2][1]) > averageTemp
    ) {
      console.log("return temp 3");
      return 2;
    } else if (
      temperatureRanges[0] &&
      parseFloat(temperatureRanges[0][0]) < averageTemp &&
      parseFloat(temperatureRanges[0][1]) > averageTemp
    ) {
      console.log("return temp 2");
      return 0;
    } else {
      //Default return middle temp range
      console.log("return temp 1");
      return 1;
    }
  };

  calculateAveragePeaksByElectrode = () => {
    const frequencyData = {};

    const processMetadata = (metadata) => {
      metadata.forEach((item) => {
        const freq = item["Frequency Hz"];
        const electrode = item["Electrode"];
        const peak = this.getPeakCurrentValue(item);
        if (!frequencyData[electrode]) {
          frequencyData[electrode] = {};
        }
        if (!frequencyData[electrode][freq]) {
          frequencyData[electrode][freq] = { sum: 0, count: 0 };
        }
        frequencyData[electrode][freq].sum += peak;
        frequencyData[electrode][freq].count++;
      });
    };

    processMetadata(sample_metadata);
    processMetadata(reagentS_metadata);
    processMetadata(reagentB_metadata);
    processMetadata(reagentA_metadata);

    const result = {};
    for (const [electrode, freqs] of Object.entries(frequencyData)) {
      const averages = Object.entries(freqs)
        .map(([freq, data]) => ({
          freq: parseFloat(freq),
          avgPeak: data.sum / data.count || 1,
        }))
        .sort((a, b) => b.freq - a.freq);

      // Failsafe, though there should always be 6 different frequencies
      while (averages.length < 6) {
        averages.push({ freq: 1, avgPeak: 1 });
      }
      result[electrode] = {
        FreqOn1: averages[0].avgPeak,
        FreqOn2: averages[1].avgPeak,
        FreqNR1: averages[2].avgPeak,
        FreqNR2: averages[3].avgPeak,
        FreqOff1: averages[4].avgPeak,
        FreqOff2: averages[5].avgPeak,
      };
    }

    return result;
  };

  averageArray = (arr) => {
    console.log(JSON.stringify(arr), "arr");
    // Filter out invalid entries
    const validNumbers = arr.filter(
      (num) => num !== null && !isNaN(num) && num > 0
    );
    // Check if there are no valid numbers
    if (!validNumbers.length) return 0; // Handle cases where there are no valid numbers to avoid division by zero
    // Calculate the sum of the valid numbers
    const sum = validNumbers.reduce((acc, val) => acc + val, 0);
    // Return the average, dividing by the count of valid numbers
    return sum / validNumbers.length;
  };

  reAddDummyMetaData = () => {
    console.log(JSON.stringify(notPushedMetaData));
    if (notPushedMetaData && Array.isArray(notPushedMetaData)) {
      // Ensure it's an array and not undefined
      for (const row of notPushedMetaData) {
        if (!row) continue; // Skip if the row is undefined
        if (row["Peak_Current pA (Reagent A)"] !== undefined)
          reagentA_metadata.push(row);
        else if (row["Peak_Current pA (Reagent B)"] !== undefined)
          reagentB_metadata.push(row);
        else if (row["Peak_Current pA (Reagent S)"] !== undefined)
          reagentS_metadata.push(row);
        else if (row["Peak_Current pA (Sample)"] !== undefined)
          sample_metadata.push(row);
      }
    }
  };

  prepare_export = async () => {
    const combined_ratios = {
      ...reagentA_ratios,
      ...sample_ratios,
      ...reagentS_ratios,
      ...reagentB_ratios,
    };
    console.log(JSON.stringify(combined_ratios), "combined_ratios");
    Object.keys(combined_ratios).forEach((ratio_label) => {
      outputHeadersAsStrings.unshift(ratio_label);
    });
    const tempRangeIndex = this.calculateTemperatureRangeIndex();
    const correctConcentrationValues = concentrationParameters[tempRangeIndex];
    const result = await fetch(
      "https://9dc44g79m0.execute-api.us-east-1.amazonaws.com/dev/calculateConcentrationElapta",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          combined_ratios,
          reagentALength: reagentA_metadata.length,
          reagentSLength: reagentS_metadata.length,
          correctConcentrationValues,
        }),
      }
    ).catch((error) => console.error("Error:", error));
    const finalConcentrations = await result.json();
    finalConcentrations.Sample_Ratio = sample_ratios["Sample_Total_Average"];
    Object.keys(finalConcentrations).forEach((concentration_label) => {
      outputHeadersAsStrings.unshift(concentration_label);
    });
    outputHeadersAsStrings.forEach((header) => {
      outputHeadersAsObjects.push({ label: header, key: header });
    });
    let objectStore = db
      .transaction(["dataBuffer"], "readwrite")
      .objectStore("dataBuffer");
    let req2 = objectStore.getAll();
    req2.onerror = (e) => {
      console.log(e);
    };
    req2.onsuccess = async (e) => {
      // Calculate predictions here before dummy data is added
      const predictionDataArray = this.prepareDataForPrediction();
      const predictionResults = await this.getPredictions(predictionDataArray);

      this.reAddDummyMetaData();
      const output_data = this.formatCSVOutput(
        finalConcentrations,
        reagentS_ratios,
        sample_ratios,
        reagentB_ratios,
        e.target.result,
        predictionResults
      );
      console.log("Completed");
      console.log(output_data, "output_data");
    };
  };

  prepareDataForPrediction = () => {
    const averagePeaksByElectrode = this.calculateAveragePeaksByElectrode();
    return Object.entries(averagePeaksByElectrode).map(
      ([electrode, peaks]) => ({
        "Electrode number": parseFloat(electrode),
        Temperature: 24, // Hardcoded to 24 to match test data (maryam)
        FreqOn1: peaks.FreqOn1,
        FreqOn2: peaks.FreqOn2,
        FreqOff1: peaks.FreqOff1,
        FreqOff2: peaks.FreqOff2,
        FreqNR1: peaks.FreqNR1,
        FreqNR2: peaks.FreqNR2,
      })
    );
  };

  getPredictions = async (predictionDataArray) => {
    const predictionResults = [];
    for (const predictionData of predictionDataArray) {
      try {
        const response = await fetch("https://zl-api.techfren.net/predict", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(predictionData),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const predictionResult = await response.json();
        predictionResults.push(predictionResult);
      } catch (error) {
        console.error("Error sending data to prediction API:", error);
      }
    }
    return predictionResults;
  };

  generateFileName = () => {
    return (
      this.state.device_name +
      "_" +
      [
        new Date().getDate(),
        new Date().getMonth() + 1,
        new Date().getFullYear(),
      ].join("-") +
      "_" +
      [
        new Date().getHours(),
        new Date().getMinutes(),
        new Date().getSeconds(),
      ].join("-")
    );
  };

  // turns object array into string 2d array
  // md is meta_data, array of objects
  // this method is now also used for the raw data
  createStringArrayFromObjArray = (objArray) => {
    console.log(JSON.stringify(objArray), "objArray");
    if (!objArray || objArray.length === 0) return [];
    // Collect all unique keys
    const allKeys = new Set();
    objArray.forEach((obj) =>
      Object.keys(obj).forEach((key) => allKeys.add(key))
    );
    const headers = Array.from(allKeys);
    // Create result array with headers
    const result = [headers];
    // Add data rows
    objArray.forEach((obj) => {
      const row = headers.map((key) =>
        obj[key] !== undefined ? obj[key] : ""
      );
      result.push(row);
    });
    return result;
  };

  formatCSVOutput = async (
    finalConcentrations,
    reagentS_ratios,
    sample_ratios,
    reagentB_ratios,
    raw_data,
    predictionResults
  ) => {
    console.log(JSON.stringify(reagentA_ratios), "A_ratios");
    console.log(JSON.stringify(reagentB_ratios), "B-ratios");
    console.log(JSON.stringify(reagentS_ratios), "S-ratios");
    console.log(JSON.stringify(sample_ratios), "Sample-Ratios");
    let result = [];
    result.push(["Device", this.state.device_name]);
    result.push([""]);
    //Sensor Response
    result.push(["Sensor Response"]);
    result.push([""]);
    const rA_keys = Object.keys(reagentA_ratios);
    const rA_vals = Object.values(reagentA_ratios);
    const rB_keys = Object.keys(reagentB_ratios);
    const rB_vals = Object.values(reagentB_ratios);
    const rS_keys = Object.keys(reagentS_ratios);
    const rS_vals = Object.values(reagentS_ratios);
    const sample_keys = Object.keys(sample_ratios);
    const sample_vals = Object.values(sample_ratios);
    const maxLength = math.max(
      sample_keys.length,
      rA_keys.length,
      rB_keys.length,
      rS_keys.length
    );
    for (let i = 0; i < maxLength; i++) {
      const currentRow = [];
      if (rA_keys.length > 0) {
        currentRow.push(rA_keys[i]);
        currentRow.push(rA_vals[i]);
        currentRow.push("");
      }
      if (rB_keys.length > 0) {
        currentRow.push(rB_keys[i]);
        currentRow.push(rB_vals[i]);
        currentRow.push("");
      }
      if (sample_keys.length > 0) {
        currentRow.push(sample_keys[i]);
        currentRow.push(sample_vals[i]);
        currentRow.push("");
      }
      if (rS_keys.length > 0) {
        currentRow.push(rS_keys[i]);
        currentRow.push(rS_vals[i]);
      }
      result.push(currentRow);
    }
    if (rS_keys.length > 0) {
      result.push([
        "Normalized to Saturation",
        sample_ratios["Sample_Total_Average"] /
          reagentS_ratios["ReagentS_Total_Average"],
      ]);
    }
    if (rB_keys.length > 0) {
      console.log(
        sample_ratios["Sample_Total_Average"],
        "sample_ratios[Sample_Total_Average]"
      );
      console.log(
        reagentB_ratios["ReagentB_Total_Average"],
        "sample_ratios[Sample_Total_Average]"
      );
      console.log(
        sample_ratios["Sample_Total_Average"] /
          reagentB_ratios["ReagentB_Total_Average"],
        "normalized to blank"
      );
      result.push([
        "Normalized to Blank",
        sample_ratios["Sample_Total_Average"] /
          reagentB_ratios["ReagentB_Total_Average"],
      ]);
    }
    result.push([""]);
    result.push([""]);
    result.push(["Test Result"]);
    result.push([""]);
    result.push([
      "Concentration3",
      finalConcentrations["Concentration3"],
      "",
      "Concentration1",
      finalConcentrations["Concentration1"],
      "",
      "Concentration5",
      this.state.selectCalibrated === "calibr-s"
        ? finalConcentrations["Concentration5"]
        : "",
    ]);
    result.push([
      "Concentration4",
      finalConcentrations["Concentration4"],
      "",
      "Concentration2",
      finalConcentrations["Concentration2"],
      "",
      "Concentration6",
      this.state.selectCalibrated === "calibr-s"
        ? finalConcentrations["Concentration6"]
        : "",
    ]);
    result.push([""]);
    const avgReagentSConcentration = this.averageArray([
      finalConcentrations["Concentration5"],
      finalConcentrations["Concentration6"],
    ]);
    const avgReagentBConcentration = this.averageArray([
      finalConcentrations["Concentration3"],
      finalConcentrations["Concentration4"],
    ]);
    const avgFreeConcentration = this.averageArray([
      finalConcentrations["Concentration1"],
      finalConcentrations["Concentration2"],
    ]);
    result.push([
      "Average Reagent B Concentration",
      avgReagentBConcentration ? avgReagentBConcentration : "",
      "",
      "Calibration Free Result",
      avgFreeConcentration,
      "",
      "Average Reagent S Concentration",
      avgReagentSConcentration && this.state.selectCalibrated === "calibr-s"
        ? avgReagentSConcentration
        : "",
    ]);
    result.push([""]);
    result.push([""]);
    result.push([""]);
    result.push([""]);
    result.push([["Calibration Free Concentration"], avgFreeConcentration]);
    result.push([""]);
    const calibratedBResult = this.averageArray([
      avgFreeConcentration,
      avgReagentBConcentration,
    ]);
    const calibratedSResult = this.averageArray([
      avgFreeConcentration,
      avgReagentSConcentration,
    ]);
    result.push([
      ["Calibrated Reagent B Result"],
      avgReagentBConcentration ? calibratedBResult : "",
    ]);
    result.push([""]);
    result.push([
      ["Calibrated Reagent S Result"],
      this.state.selectCalibrated === "calibr-s" && avgReagentSConcentration
        ? calibratedSResult
        : "",
    ]);
    const returnCorrectConcentration = (f, b, s) => {
      if (this.state.selectCalibrated === "calibr-f") return f;
      if (this.state.selectCalibrated === "calibr-b") return b;
      if (this.state.selectCalibrated === "calibr-s") return s;
      return null;
    };
    result.push([""]);
    result.push([""]);
    // Add the prediction results to the CSV output
    if (predictionResults && predictionResults.length > 0) {
      predictionResults.forEach((predictionResult, index) => {
        result.push([`Electrode ${index + 1} Predictions`]);
        result.push([
          "GradientBoostingRegressor",
          predictionResult.individual_predictions.GBoost[0],
        ]);
        result.push([
          "KNeighborsRegressor",
          predictionResult.individual_predictions.Knn[0],
        ]);
        result.push([
          "KernelRidge",
          predictionResult.individual_predictions.KRR[0],
        ]);
        result.push([
          "RandomForestRegressor",
          predictionResult.individual_predictions.RandomForest[0],
        ]);
        result.push([
          "XGBRegressor",
          predictionResult.individual_predictions.XGBoost[0],
        ]);
        // result.push([
        //   "Stacking Prediction",
        //   predictionResult.stacking_prediction[0],
        // ]);
        result.push([""]);
      });
    }
    result.push([""]);
    result.push([""]);
    const sortMetadata = (array) => {
      const outputArray = [];

      // put y0 in outputArray[0] etc

      for (const element of array) {
        if (!element) continue;
        let trimmedString = element["Curve"].substr(
          1,
          element["Curve".length - 1]
        );
        trimmedString.substr(0, trimmedString.length - 4);
        outputArray[parseInt(trimmedString)] = element;
      }
      return outputArray;
    };
    result = result.concat(
      this.createStringArrayFromObjArray(sortMetadata(reagentA_metadata))
    );
    result.push([""]);
    result = result.concat(
      this.createStringArrayFromObjArray(sortMetadata(reagentB_metadata))
    );
    result.push([""]);
    result = result.concat(
      this.createStringArrayFromObjArray(sortMetadata(sample_metadata))
    );
    result.push([""]);
    result = result.concat(
      this.createStringArrayFromObjArray(sortMetadata(reagentS_metadata))
    );
    result.push([""]);
    result.push([""]);
    //Pad array so all rows are same length so we can add raw data to the right
    for (const el of result) {
      while (el.length < 22) {
        el.push("");
      }
    }
    // Process raw data and append it to the result array
    const rawDataArray = this.createStringArrayFromObjArray(raw_data);
    // Sort the columns of raw data so we get y1-yn in order
    const sortOrder = rawDataArray[0]
      .slice(1)
      .map((val, index) => ({
        originalIndex: index + 1,
        value: val,
        sortKey: parseInt((val.match(/y(\d+)/) || [])[1] || "0"),
      }))
      .sort((a, b) => a.sortKey - b.sortKey);
    for (const indexString in rawDataArray) {
      const i = parseInt(indexString);
      const row = rawDataArray[i];
      const sortedYVals = [row[0]].concat(
        sortOrder.map((item) => row[item.originalIndex])
      );
      if (result[i]) {
        result[i] = result[i].concat(sortedYVals);
      } else {
        result.push(new Array(22).fill("").concat(sortedYVals));
      }
    }
    // We send calibration free result to device before sending others if non calib-f selected
    if (this.state.selectCalibrated !== "calibr-f") {
      await this.test_write_result(
        Math.round(Math.max(avgFreeConcentration * 100, 0))
      );
    }
    this.test_write_result(
      Math.round(
        Math.max(
          returnCorrectConcentration(
            avgFreeConcentration,
            calibratedBResult,
            calibratedSResult
          ) * 100,
          0
        )
      )
    );
    if (this.state.cloudRun)
      this.save_to_cloud("output/" + this.generateFileName() + ".csv", result);
    const totalVancomycinLevel = Math.max(
      returnCorrectConcentration(
        avgFreeConcentration,
        calibratedBResult,
        calibratedSResult
      ),
      0
    );
    this.setState({
      outputData: result,
      save_csv_data: [{ Concentration: totalVancomycinLevel }],
      show_error: true,
      error_message: "Test Completed",
      preparing_save: false,
      predictionResults: predictionResults.map(
        (result) => result.individual_predictions
      ),
      // stackingPredictions: predictionResults.map(result => result.stacking_prediction[0]),
      totalVancomycinLevel,
    });
    return result;
  };

  //send the expected output row count it to the relative characteristic (Progress Bar)
  updateDeviceProgressBar = async (value) => {
    if (process.env.REACT_APP_AJ_TEST_PC === "enabled") return;
    console.log(value, "progressBarVal");
    const buffer = new ArrayBuffer(2);
    const dv = new DataView(buffer);
    dv.setUint16(0, value, false);
    let progressBarCharacteristic = await this.state.current_service.getCharacteristic(
      "16d30bcd-f148-49bd-b127-8042df63ded0"
    );
    progressBarCharacteristic.writeValue(dv.buffer);
  };

  //Calculates expected output row count for a given method file
  calculateOutputRowNumber = (data) => {
    let outputRowSum = 0;
    if (data && data.length > 1) {
      const rAHeaders = data[0];
      const weSelectIndex = rAHeaders.indexOf("WE Select");
      for (const row of data.slice(1)) {
        // Turn we select/electrode into test count
        // 10000 (means electrode 5) & 1010000 (means electrode 5 and 7)
        if (row.length >= weSelectIndex)
          outputRowSum += (row[weSelectIndex].split("1").length - 1) * 2;
      }
    }
    return outputRowSum;
  };

  //Extracts Parameters used for contentration equation from sample method file
  extractConcentrationParameters = (data) => {
    let concentrationParameterNames = [
      "A1",
      "B1",
      "N1",
      "A2",
      "B2",
      "N2",
      "A3",
      "B3",
      "N3",
      "K01",
      "S01",
      "Smax1",
      "eN1",
      "K02",
      "S02",
      "Smax2",
      "eN2",
      "K03",
      "S03",
      "Smax3",
      "eN3",
    ];
    let headers = data[0];
    let firstRow, secondRow, thirdRow;
    let identifier = this.state.serumOrBlood === "serum" ? "S" : "B";
    // B22<T24
    // B24<T26
    // B26<T28
    // S22<T24
    // S24<T26
    // S26<T28
    const temperatureRangeIndex = headers.indexOf("Temperature Range");
    console.log(data[4]);
    if (data[4] && data[4][temperatureRangeIndex].indexOf(identifier) === 0) {
      console.log("second 3");
      firstRow = data[4];
      secondRow = data[5];
      thirdRow = data[6];
    } else {
      console.log("first 3");
      if (data.length < 4) return;
      firstRow = data[1];
      secondRow = data[2];
      thirdRow = data[3];
    }

    if (temperatureRangeIndex !== -1) {
      temperatureRanges = [
        [firstRow[temperatureRangeIndex].split("<T")],
        [secondRow[temperatureRangeIndex].split("<T")],
        [thirdRow[temperatureRangeIndex].split("<T")],
      ];
    }

    concentrationParameterNames.forEach((header) => {
      let index = headers.indexOf(header);
      if (index !== -1) {
        concentrationParameters[0][header] = parseFloat(firstRow[index]);
        concentrationParameters[1][header] = parseFloat(secondRow[index]);
        concentrationParameters[2][header] = parseFloat(thirdRow[index]);
      }
    });

    console.log(
      JSON.stringify(concentrationParameters),
      "concentrationParameters"
    );
  };

  handle_load_sample_file = (data) => {
    this.setState({ sampleMethodData: data });
  };

  handle_load_reagentA_file = (data) => {
    this.setState({ reagentAMethodData: data });
  };

  beforeRunningReagentATest = () => {
    return (
      this.state.reagentAMethodData !== null &&
      !this.state.runningReagentA &&
      !this.state.skipReagentA
    );
  };

  prepare_skip_run = () => {
    let skipped_message;
    if (this.state.selectCalibrated === "calibr-b") {
      skipped_message = "Add Reagent B to the cartridge";
    } else {
      skipped_message = "Add the patient sample to the cartridge";
    }
    this.setState({
      menu_open: false,
      show_error: true,
      skipReagentA: true,
      error_message: skipped_message,
    });
  };

  get_cloud_file = async (fileName) => {
    try {
      const response = await fetch(apiURL + "/readCSV", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          file: fileName,
        }),
      }).catch((error) => console.error("Error:", error));
      return response.json();
    } catch (e) {
      console.error(e);
      this.setState({
        loading: false,
        menu_open: false,
        show_error: true,
        error_message: "Failed to load " + fileName + " from cloud.",
      });
    }
  };

  save_to_cloud = async (fileName, data) => {
    try {
      const response = await fetch(apiURL + "/saveCSV", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          fileKey: fileName,
          data,
        }),
      }).catch((error) => console.error("Error:", error));
      return response.json();
    } catch (e) {
      console.error("failed to save to cloud", e);
    }
  };

  load_from_cloud = async () => {
    console.log("loading...");
    this.setState({ menu_open: false, loading: true, cloudRun: true });
    try {
      let loadedReagentAMethod = null;
      if (!this.state.skipReagentA) {
        loadedReagentAMethod = await this.get_cloud_file("reagentA_method.csv");
      }
      const loadedSampleMethod = await this.get_cloud_file("sample_method.csv");
      this.setState({
        sampleMethodData: loadedSampleMethod.data,
        reagentAMethodData: loadedReagentAMethod
          ? loadedReagentAMethod.data
          : null,
        loading: false,
      });
      this.prepare_start_run_test();
    } catch (error) {
      // Handle any errors that occur during the fetch and set loading to false
      console.error("Error:", error);
      this.setState({
        loading: false,
        show_error: true,
        error_message: "Failed to load data from cloud: " + error.message,
      });
    }
  };

  prepare_start_run_test = async () => {
    if (this.state.skipReagentA) {
      this.prepare_skip_run();
    } else {
      //Step 1
      this.setState({
        menu_open: false,
        show_error: true,
        error_message: "Add reagent A to the cartridge",
      });
    }
  };

  start_run_test = async () => {
    baseCurrentMax =
      parseFloat(
        this.state.sampleMethodData[0][
          this.state.sampleMethodData[0].indexOf("max base current")
        ]
      ) || Infinity;
    this.extractConcentrationParameters(this.state.sampleMethodData);
    const data = this.beforeRunningReagentATest()
      ? this.state.reagentAMethodData
      : this.state.sampleMethodData;
    this.updateDeviceProgressBar(this.calculateOutputRowNumber(data));
    this.setState(
      {
        runningReagentA: this.beforeRunningReagentATest(),
        menu_open: false,
        loading: true,
        loading_file: false,
        automated_test: true,
        show_error: "",
        error_message: "",
      },
      () => {
        // Load method file and start automated testing
        let headers = data[0];
        let tempData = [];
        for (let i = 1; i < data.length; i++) {
          let tempRow = {};
          headers.forEach((val, index) => {
            tempRow[val] = data[i][index];
          });
          tempData.push(tempRow);
        }
        let pre_curve_count = headers.length - 1;
        let curves = [];
        for (let i = 0; i <= pre_curve_count; i++) {
          curves.push(i);
        }

        if (this.state.automated_test) {
          tempData.sort(function(a, b) {
            return a.Order - b.Order;
          });
          automation_configs = tempData;
          this.run_automated_tests(tempData);
        } else {
          this.props.updateGraphMethod(tempData, curves);
          this.setState({ loading: false, dataBuffer: tempData });
        }
      }
    );
  };

  run_automated_tests = async (test_configs) => {
    if (test_configs.length === 0) {
      if (this.state.selectCalibrated === "calibr-f") {
        if (this.state.runningReagentA)
          this.prepare_save_reagentA(
            "Discard the reagent A, add patient sample to the cartridge"
          );
        else if (this.state.runningSampleTest)
          this.prepare_save_sample(
            "Discard the patient sample, add reagent S to the cartridge",
            true
          );
      }
      if (this.state.selectCalibrated === "calibr-b") {
        if (this.state.runningReagentA)
          this.prepare_save_reagentA(
            "Discard the reagent A, add Reagent B to the cartridge"
          );
        else if (ranReagentB && !this.state.runningSampleTest)
          this.prepare_save_reagentB();
        else
          this.prepare_save_sample(
            "Discard the patient sample, add reagent B to the cartridge",
            true
          );
      }
      if (this.state.selectCalibrated === "calibr-s") {
        if (this.state.runningReagentA)
          this.prepare_save_reagentA(
            "Discard the reagent A, add patient sample to the cartridge"
          );
        else if (this.state.runningSampleTest && !ranReagentS)
          this.prepare_save_sample(
            "Discard the patient sample, add reagent S to the cartridge"
          );
        else this.prepare_save_reagentS();
      }
      // if (this.state.runningReagentA) {
      //   //Step 2
      //   this.prepare_save_reagentA();
      // } else if (ranReagentB && !ranReagentC) {
      //   //Step 4
      //   this.prepare_save_reagentB();
      // } else if (ranReagentC) {
      //   //Step 5
      //   this.prepare_save_reagentC();
      // } else {
      //   //Step 3
      //   this.prepare_save_sample();
      // }
    } else {
      let row = test_configs[0];
      console.log(JSON.stringify(row), "current test_config");
      if (!row.hasOwnProperty("Order")) {
        alert("Column with header 'Order' not found");
        return;
      }
      if (
        typeof row.Freq === "undefined" ||
        typeof row.FreqNR === "undefined" ||
        typeof row["Start Volt"] === "undefined" ||
        typeof row.Range === "undefined" ||
        typeof row.RangeNR === "undefined" ||
        typeof row["WE Select"] === "undefined" ||
        row.Freq == "" ||
        row.FreqNR == "" ||
        row["Start Volt"] == "" ||
        row.Range == "" ||
        row.RangeNR == "" ||
        row["WE Select"] == ""
      ) {
        automation_configs.shift();
        this.run_automated_tests(automation_configs);
      } else {
        let swv = 3;
        if (typeof row["SWVs"] === "undefined" || row["SWVs"] == "") {
          try {
            let SWV_characteristic = await this.state.current_service.getCharacteristic(
              "16d30bce-f148-49bd-b127-8042df63ded0"
            );
            let val = await SWV_characteristic.readValue();
            swv = val.getUint8();
          } catch (e) {
            console.log(e, ": failed capturing SWV");
            alert("Failed to capture SWV");
            swv = "";
          }
        } else {
          swv = parseInt(row["SWVs"]);
        }

        let EquationType = 1;
        if (typeof row["Equation"] !== "undefined" && row["Equation"] != "") {
          // eslint-disable-next-line default-case
          switch (row["Equation"].toLowerCase()) {
            case "equation 1":
            case "linear":
            case "1":
              EquationType = 1;
              break;
            case "equation 2":
            case "hyperbolic":
            case "2":
              EquationType = 2;
              break;
            case "equation 3":
            case "exponential":
            case "3":
              EquationType = 3;
              break;
          }
        } else if (this.state.firstRunOfAutomation) {
          alert(
            "Failed to capture equation type in automated test specifications. Test will still run with default values."
          );
        }

        let sweepDirection = 0;
        if (
          typeof row["Sweep Direction"] !== "undefined" &&
          row["Sweep Direction"] != ""
        ) {
          sweepDirection = parseInt(row["Sweep Direction"]);
        } else if (this.state.firstRunOfAutomation) {
          alert(
            "Failed to capture Sweep Direction value in automated test specifications. Test will still run with default values."
          );
        }
        previousFreq = null;
        previousPeak = null;
        previousError = null;
        this.setState(
          {
            firstRunOfAutomation: false,
            frequency: row.Freq,
            frequency_NR: row.FreqNR,
            start_voltage: parseFloat(row["Start Volt"]),
            range: row.Range,
            range_NR: row.RangeNR,
            SWV: swv,
            we_selected: parseInt(row["WE Select"]),
            selectedEquation: concentrationEquationOptions[EquationType - 1],
            selectedSweepDirection: sweepDirectionOptions[sweepDirection],
          },
          () => {
            this.updateCharacteristics(true);
          }
        );
      }
    }
  };

  updateBattery = () => {
    if (this.state.current_service) {
      this.state.current_service
        .getCharacteristic("16d30bca-f148-49bd-b127-8042df63ded0")
        .then((characteristic) => {
          characteristic.readValue().then((value) => {
            this.setState({ battery_level: value.getInt8() });
          });
        })
        .catch((error) => {
          console.log("Battery Characteristic Read", error);
        });
    }
  };

  changeEquation = (selectedEquation) => {
    this.setState({ selectedEquation });
  };

  changeSweepDirection = (selectedSweepDirection) => {
    this.setState({ selectedSweepDirection });
  };

  render() {
    return (
      <div
        style={{
          zIndex: 1,
          position: "absolute",
          width: "20vw",
          height: "100vh",
          right: 0,
        }}
      >
        {this.state.show_error && (
          <div className="popup">
            <div style={{ fontSize: 26, textAlign: "center", width: "100%" }}>
              {this.state.error_message}
            </div>
            {this.state.error_message === "Add reagent A to the cartridge" && (
              <div
                className="button start-test"
                style={{ height: 26 }}
                onClick={() => this.start_run_test()}
              >
                Start
              </div>
            )}
            {this.state.error_message === "Add Reagent B to the cartridge" && (
              <div
                className="button start-test"
                style={{ height: 26 }}
                onClick={() => this.runReagentB("Reagent A")}
              >
                Start
              </div>
            )}
            {this.state.error_message ===
              "Add the patient sample to the cartridge" && (
              <div
                className="button start-test"
                style={{ height: 26 }}
                onClick={() => this.runSampleTest()}
              >
                Start
              </div>
            )}
            {this.state.error_message ===
              "Discard the reagent A, add Reagent B to the cartridge" && (
              <div
                className="button start-test"
                style={{ height: 26 }}
                onClick={() => this.runReagentB("Reagent A")}
              >
                Continue
              </div>
            )}
            {this.state.error_message ===
              "Discard Reagent B, add the patient sample to the cartridge" && (
              <div
                className="button start-test"
                style={{ height: 26 }}
                onClick={() => this.runSampleTest("Reagent B")}
              >
                Continue
              </div>
            )}
            {this.state.error_message ===
              "Discard the reagent A, add patient sample to the cartridge" && (
              <div
                className="button start-test"
                style={{ height: 26 }}
                onClick={() => this.runSampleTest()}
              >
                Continue
              </div>
            )}
            {this.state.error_message == "Device Disconnected" && (
              <div
                className="button start-test"
                style={{ height: 26 }}
                onClick={this.pairDevice}
              >
                Pair device
              </div>
            )}
            {this.state.error_message ==
              "Discard the patient sample, add reagent B to the cartridge" && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div
                  className="button start-test"
                  style={{ height: 26 }}
                  onClick={() => this.runReagentB()}
                >
                  Continue
                </div>
              </div>
            )}
            {this.state.error_message ==
              "Discard the patient sample, add reagent S to the cartridge" && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div
                  className="button start-test"
                  style={{ height: 26 }}
                  onClick={this.runReagentS}
                >
                  Continue
                </div>
              </div>
            )}
            {this.state.error_message == "Test Completed" && (
              <>
                <h2 style={{ width: "100%", textAlign: "center" }}>
                  The concentration is :{" "}
                  {this.state.totalVancomycinLevel.toFixed(2) < 50
                    ? this.state.totalVancomycinLevel.toFixed(2) + "mg/L"
                    : ">50 mg/L"}
                </h2>
                <h3 style={{ width: "100%", textAlign: "center" }}>
                  Predictions:
                </h3>
                {this.state.predictionResults.map((result, index) => (
                  <div key={index}>
                    <h4 style={{ width: "100%", textAlign: "center" }}>
                      Electrode {index + 1}
                    </h4>
                    <ul style={{ textAlign: "center" }}>
                      {Object.entries(result).map(([key, value]) => (
                        <li key={key}>
                          {key}: {value[0]}
                        </li>
                      ))}
                    </ul>
                    <h4 style={{ width: "100%", textAlign: "center" }}>
                      Stacking Prediction:{" "}
                      {
                        //{this.state.stackingPredictions[index]}
                      }
                    </h4>
                  </div>
                ))}
                <CSVLink
                  onClick={this.exportedData}
                  data={this.state.outputData}
                  style={{ textDecoration: "none", height: 30 }}
                  filename={this.generateFileName() + ".csv"}
                  className="button start-test"
                >
                  Save CSV
                </CSVLink>
              </>
            )}
          </div>
        )}
        {this.state.menu_open ? (
          <div>
            <img
              onClick={this.toggleMenuOpen}
              src={require("../../img/close.png")}
              alt="Settings"
              style={{
                zIndex: 2,
                top: 9,
                right: "6vw",
                width: 40,
                cursor: "pointer",
                position: "absolute",
              }}
            />
            {this.state.characteristics_menu_open ? (
              <div className="settings-popup">
                <label className="option">
                  Frequency 1
                  <div>
                    <input
                      onChange={(e) => this.handleInputParameterChange(e)}
                      value={this.state.frequency}
                      name="frequency"
                      type="number"
                      className="input-text small"
                    />
                    Hz
                  </div>
                </label>

                <label className="option">
                  Frequency 2
                  <div>
                    <input
                      onChange={(e) => this.handleInputParameterChange(e)}
                      value={this.state.frequency_NR}
                      name="frequency_NR"
                      type="number"
                      className="input-text small"
                    />
                    Hz
                  </div>
                </label>

                <label className="option">
                  Start Voltage
                  <div>
                    <input
                      onChange={(e) => this.handleInputParameterChange(e)}
                      value={this.state.start_voltage}
                      name="start_voltage"
                      type="number"
                      className="input-text small"
                    />
                    mV
                  </div>
                </label>

                <label className="option">
                  Range 1
                  <div>
                    <input
                      onChange={(e) => this.handleInputParameterChange(e)}
                      value={this.state.range}
                      name="range"
                      type="number"
                      className="input-text small"
                    />
                  </div>
                </label>

                <label className="option">
                  Range 2
                  <div>
                    <input
                      onChange={(e) => this.handleInputParameterChange(e)}
                      value={this.state.range_NR}
                      name="range_NR"
                      type="number"
                      className="input-text small"
                    />
                  </div>
                </label>

                <label className="option">
                  WE Selected
                  <div>
                    <input
                      onChange={(e) => this.handleInputParameterChange(e)}
                      value={this.state.we_selected}
                      name="we_selected"
                      type="number"
                      className="input-text small"
                    />
                  </div>
                </label>

                <label className="option">
                  SWVs
                  <div>
                    <input
                      onChange={(e) => this.handleInputParameterChange(e)}
                      value={this.state.SWV}
                      name="SWV"
                      type="number"
                      className="input-text small"
                    />
                  </div>
                </label>

                <label className="option">
                  Equation
                  <div>
                    <Select
                      value={this.state.selectedEquation}
                      onChange={this.changeEquation}
                      options={concentrationEquationOptions}
                    />
                  </div>
                </label>
                <label className="option">
                  Practical Limit of Detection
                  <div>
                    <input
                      onChange={(e) => this.handleInputParameterChange(e)}
                      value={this.state.concentration_avg_filter}
                      name="concentration_avg_filter"
                      type="number"
                      className="input-text small"
                    />
                  </div>
                </label>

                <label className="option">
                  Sweep Direction
                  <div>
                    <Select
                      value={this.state.selectedSweepDirection}
                      onChange={this.changeSweepDirection}
                      options={sweepDirectionOptions}
                    />
                  </div>
                </label>

                <div className="flex">
                  <div
                    className="button start-test"
                    onClick={() => {
                      this.updateCharacteristics(false);
                    }}
                  >
                    {" "}
                    Update{" "}
                  </div>
                </div>
              </div>
            ) : (
              <div className="settings-popup">
                {this.state.loading_file ? (
                  <>
                    <label>
                      <input
                        type="radio"
                        value="calibr-b"
                        checked={this.state.selectCalibrated === "calibr-b"}
                        onChange={(val) => {
                          this.setState({ selectCalibrated: val.target.value });
                        }}
                      />
                      CALIBR-B
                    </label>
                    <label>
                      <input
                        type="radio"
                        value="calibr-s"
                        checked={this.state.selectCalibrated === "calibr-s"}
                        onChange={(val) => {
                          this.setState({ selectCalibrated: val.target.value });
                        }}
                      />
                      CALIBR-S
                    </label>
                    <label>
                      <input
                        type="radio"
                        value="calibr-f"
                        checked={this.state.selectCalibrated === "calibr-f"}
                        onChange={(val) => {
                          console.log(val.target.value, "val");
                          this.setState({ selectCalibrated: val.target.value });
                        }}
                      />
                      CALIBR-F
                    </label>
                    {this.state.selectCalibrated && (
                      <>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "flex-end",
                          }}
                        >
                          <CSVReader
                            cssClass="load-csv"
                            label={`Reagent A csv file`}
                            onFileLoaded={this.handle_load_reagentA_file}
                          />
                          <div>
                            <button
                              onClick={() => {
                                this.setState({
                                  skipReagentA: !this.state.skipReagentA,
                                });
                              }}
                              style={{
                                width: 86,
                                height: 60,
                                backgroundColor: this.state.skipReagentA
                                  ? "#7dbcd2"
                                  : "#efefef",
                              }}
                            >
                              Skip Reagent A{" "}
                              {this.state.skipReagentA ? "(ON)" : "(OFF)"}
                            </button>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <CSVReader
                            cssClass="load-csv"
                            label={`Sample csv file`}
                            onFileLoaded={this.handle_load_sample_file}
                          />

                          <div>
                            <label>
                              <input
                                type="radio"
                                value="Serum"
                                checked={this.state.serumOrBlood === "serum"}
                                onChange={(val) => {
                                  console.log(val.target.value, "val");
                                  this.setState({
                                    serumOrBlood: "serum",
                                  });
                                }}
                              />
                              Serum
                            </label>
                            <label>
                              <input
                                type="radio"
                                value="Blood"
                                checked={this.state.serumOrBlood === "blood"}
                                onChange={(val) => {
                                  console.log(val.target.value, "val");
                                  this.setState({
                                    serumOrBlood: "blood",
                                  });
                                }}
                              />
                              Blood
                            </label>
                          </div>
                        </div>
                        {(this.state.skipReagentA ||
                          this.state.reagentAMethodData) &&
                        this.state.sampleMethodData ? (
                          <div className="flex">
                            <div
                              style={{ textAlign: "center" }}
                              className="button start-test"
                              onClick={this.prepare_start_run_test}
                            >
                              Run
                            </div>
                          </div>
                        ) : (
                          <div className="flex">
                            <div
                              className="button start-test"
                              onClick={this.load_from_cloud}
                            >
                              Load files from cloud
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <div>
                    {this.state.loading && (
                      <div className="flex">
                        <div
                          className="button start-test"
                          onClick={this.stop_automated_test}
                        >
                          Stop Automated Test
                        </div>
                      </div>
                    )}

                    <div className="flex">
                      <div
                        className="button start-test"
                        onClick={this.prepare_emergency_save}
                      >
                        Prepare Save
                      </div>
                    </div>

                    <div className="flex">
                      <div
                        className="button start-test"
                        onClick={this.disconnectDevice}
                      >
                        Disconnect Device
                      </div>
                    </div>
                    {!this.state.loading && (
                      <div className="flex">
                        <div
                          className="button start-test"
                          onClick={this.open_loading_file}
                        >
                          Load Method
                        </div>
                      </div>
                    )}
                    {!this.state.loading && (
                      <div className="flex">
                        <div
                          className="button start-test"
                          onClick={this.open_characteristics_menu}
                        >
                          Edit Method
                        </div>
                      </div>
                    )}

                    <div className="flex">
                      <div
                        className="button start-test"
                        onClick={this.pairDevice}
                      >
                        Pair Device
                      </div>
                    </div>

                    <div className="flex">
                      <div
                        className="button start-test"
                        onClick={this.start_run_test}
                      >
                        Run
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        ) : (
          //End is menu open?
          <div>
            {this.state.device_paired == null ||
            this.state.user_disconnected ? (
              <div>
                {this.state.loading ? (
                  <div style={{ marginTop: 20, marginLeft: -27 }}>
                    <Loader
                      type="TailSpin"
                      color="#7dbcd2"
                      height={68}
                      width={68}
                    />
                    Pairing...
                  </div>
                ) : (
                  <div
                    className="flex"
                    style={{ position: "absolute", height: "100vh", right: 50 }}
                  >
                    <div
                      className="pair-device-button"
                      onClick={this.pairDevice}
                    >
                      Pair Device
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div>
                {this.state.loading ? (
                  <div>
                    <br />
                    <br />
                    <div style={{ marginTop: -20, marginLeft: -27 }}>
                      <Loader
                        type="TailSpin"
                        color="#7dbcd2"
                        height={68}
                        width={68}
                      />
                    </div>
                    <img
                      onClick={this.toggleMenuOpen}
                      src={require("../../img/settings.png")}
                      alt="Settings"
                      onMouseOver={(e) =>
                        (e.currentTarget.src = require("../../img/settings_highlighted.png"))
                      }
                      onMouseOut={(e) =>
                        (e.currentTarget.src = require("../../img/settings.png"))
                      }
                      style={{
                        cursor: "pointer",
                        top: 10,
                        right: "6vw",
                        position: "absolute",
                      }}
                    />
                    <br />
                    <br />

                    {this.state.apparent_sample_output && (
                      <div style={{ marginTop: 15, fontWeight: 600 }}>
                        Apparent Sample Output:{" "}
                        {parseFloat(this.state.apparent_sample_output).toFixed(
                          2
                        )}
                      </div>
                    )}
                    <div id="status-message" className="status-message">
                      {this.state.status_message}
                    </div>
                  </div>
                ) : (
                  <div>
                    {/* <img
                      onClick={() => {
                        this.setState(
                          { selectCalibrated: "calibrated" },
                          this.prepare_start_run_test
                        );
                      }}
                      src={require("../../img/play_button.png")}
                      alt="Settings"
                      onMouseOver={(e) =>
                        (e.currentTarget.src = require("../../img/play_button_highlighted.png"))
                      }
                      onMouseOut={(e) =>
                        (e.currentTarget.src = require("../../img/play_button.png"))
                      }
                      style={{
                        cursor: "pointer",
                        top: 10,
                        right: "calc(6vw + 80px)",
                        position: "absolute",
                      }}
                    /> */}

                    <img
                      onClick={this.toggleMenuOpen}
                      src={require("../../img/settings.png")}
                      alt="Settings"
                      onMouseOver={(e) =>
                        (e.currentTarget.src = require("../../img/settings_highlighted.png"))
                      }
                      onMouseOut={(e) =>
                        (e.currentTarget.src = require("../../img/settings.png"))
                      }
                      style={{
                        cursor: "pointer",
                        top: 10,
                        right: "6vw",
                        position: "absolute",
                      }}
                    />

                    <div className="device-message">
                      Connected to: {this.state.device_name} (v
                      {this.state.firmware})
                    </div>

                    <div style={{ marginTop: 10 }}>
                      log output
                      <input
                        name="debug"
                        type="checkbox"
                        checked={this.state.debug}
                        onChange={(e) => this.handleInputParameterChange(e)}
                      />
                    </div>
                    <div
                      onClick={this.updateBattery}
                      className="no-highlight device-message"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: 0,
                      }}
                    >
                      Battery: {this.state.battery_level}%
                      <img
                        src={Refresh}
                        style={{ width: 30, marginLeft: 5 }}
                        alt="Update Battery"
                      />
                    </div>

                    {this.state.apparent_sample_output && (
                      <div style={{ marginTop: 15, fontWeight: 600 }}>
                        Apparent Sample Output:{" "}
                        {parseFloat(this.state.apparent_sample_output).toFixed(
                          2
                        )}
                      </div>
                    )}
                    <div id="status-message" className="status-message">
                      {this.state.status_message}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default SettingsMenu;
//© 2020, Zio Health Ltd. All rights reserved
